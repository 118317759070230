import { ToasterTypes } from './toaster.provider.type';

// Toaster statuses
export const TOASTER_SUCCESS = 'success';
export const TOASTER_ERROR = 'error';
export const TOASTER_WARNING = 'warning';
export const TOASTER_INFO = 'info';
export const TOASTER_LOADING = 'loading';

// icon helper
export const TOAST_ICON_MAPPER = {
  [TOASTER_SUCCESS]: TOASTER_SUCCESS,
  [TOASTER_ERROR]: TOASTER_ERROR,
  [TOASTER_WARNING]: 'error-triangle',
  [TOASTER_INFO]: 'info-reg',
  [TOASTER_LOADING]: TOASTER_LOADING, // TODO: handle icon, need animation for loading
};

// consts
export const TOAST_TIME_TO_LIVE = 4600;
export const ANIMATION_DURATION = 400;
export const TOASTS_LIMIT = 5;

// animations
export const TOASTER_HIDE = 'hide';
export const TOASTER_REVEAL = 'reveal';

export const getColorByToasterStatus = (
  toasterStatus: ToasterTypes
): string => {
  switch (toasterStatus) {
    case TOASTER_SUCCESS:
      return '#00e205';
    case TOASTER_ERROR:
      return '#f90021';
    case TOASTER_INFO:
      return '#3f8bef';
    case TOASTER_LOADING:
      return '#8d86eb';
    case TOASTER_WARNING:
      return '#fe630c';
    default:
      return 'transparent';
  }
};

export const ACTION_COMPLETION_MESSAGE_TEXT = 'All good :)';
