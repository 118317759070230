import { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import classNames from 'classnames';
import { Footer } from 'components/Footer/Footer';
import {
  learnHeaderLinks,
  exploreHeaderLinks,
  getInvolvedHeaderLinks,
  litepaperNav,
} from 'components/Header/header.const';
import { Heading } from 'components/Heading/Heading';
import { Icon } from 'components/Icon/Icon';
import { ThemeSwitcher } from 'components/ThemeButton/ThemeButton';
import sharedStyles from 'styles/sharedStyles.module.css';

import { BurgerExpanderItem } from './components/BurgerExpanderItem';
import styles from './mobileMenu.module.css';

type Props = {
  open: boolean;
  closeMobileMenu: () => void;
  isActiveThemeButton: boolean;
};

export const MobileMenu = ({ open, closeMobileMenu, isActiveThemeButton }: Props) => {
  const [expandedItem, setExpandedItem] = useState(0);

  const toggleMenuItem = useCallback((idx: number) => {
    setExpandedItem(idx);
  }, []);

  useEffect(() => {
    // use timeout because when the scroll disappears immediately, you see how the content shifts while the menu opens
    let timerId: NodeJS.Timeout;

    if (open) {
      // lock scroll when popup is open
      timerId = setTimeout(() => (document.body.style.overflow = 'hidden'), 200);
    } else {
      // unlock scroll when popup is open
      document.body.style.overflow = '';
    }

    return () => {
      clearTimeout(timerId);
    };
  }, [open]);

  return (
    <div
      className={classNames(styles.mobileMenuWrapper, sharedStyles.scrollBlock, {
        [styles.menuOpen]: open,
        [styles.menuClosed]: !open,
      })}
    >
      <div className={styles.header}>
        <div className={styles.logos}>
          <Icon id="logo" className={styles.logoIcon} />
          <Icon id="mavryk-text" className={styles.logoMavryk} />
        </div>

        <button onClick={closeMobileMenu}>
          <Icon id="menu" />
        </button>
      </div>

      <nav className={styles.navigation}>
        <BurgerExpanderItem
          text="Learn"
          items={learnHeaderLinks}
          isOpen={expandedItem === 0}
          toggleMenuItem={toggleMenuItem}
          serialNumber={0}
        />
        <BurgerExpanderItem
          text="Explore"
          items={exploreHeaderLinks}
          isOpen={expandedItem === 1}
          toggleMenuItem={toggleMenuItem}
          serialNumber={1}
        />
        <BurgerExpanderItem
          text="Get Involved"
          items={getInvolvedHeaderLinks}
          isOpen={expandedItem === 2}
          toggleMenuItem={toggleMenuItem}
          serialNumber={2}
        />

        <div>
          <Link key={litepaperNav.title} to={litepaperNav.link}>
            <Heading className={styles.link} type="none">
              {litepaperNav.title}
            </Heading>
          </Link>
        </div>

        {isActiveThemeButton && (
          <div>
            <ThemeSwitcher />
          </div>
        )}
      </nav>

      <Footer />
    </div>
  );
};
