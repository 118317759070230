import { NavDropDownItem } from 'components/NavigationDropDown/navigationDropDown.types';

import { pathnames } from '../../routes/routes';

export const blueRecolorPages = [
  pathnames.VALIDATOR,
  pathnames.DELEGATOR_TAB,
  pathnames.VAILDATOR_TAB,
  pathnames.MEDIA_KIT,
  pathnames.COMMUNITY,
  pathnames.GRANTS,
  pathnames.FOUNDATION_DELEGATIONS,
] as const;

// COMMENT THE PATH TO HAVE THE THEME SWITCHER
export const pagesWithoutThemeButton = [
  pathnames.VALIDATOR,
  pathnames.DELEGATOR_TAB,
  pathnames.VAILDATOR_TAB,
  pathnames.UTILITY_AND_ECONOMICS,
  pathnames.MEDIA_KIT,
  pathnames.COMMUNITY,
  pathnames.GRANTS,
  pathnames.GRANT_DETAILS,
  pathnames.FOUNDATION_DELEGATIONS,
  pathnames.HOME,
  pathnames.LITEPAPER,
  pathnames.RWA,
  pathnames.PROTOCOL_TREASURY,
  pathnames.LIQUIDITY_MINING,
  pathnames.ABOUT_US,
  pathnames.DOMAINS,
  pathnames.WALLET_TEASER,
] as const;

export const pagesWithoutStayTunedButton = [pathnames.HOME] as const;

export const litepaperNav = { title: 'Litepaper', link: pathnames.LITEPAPER };

export const learnHeaderLinks: NavDropDownItem[] = [
  { title: 'Validator', link: pathnames.VALIDATOR, body: 'Help run the Mavryk Network' },
  {
    title: 'Delegator',
    link: pathnames.DELEGATOR_TAB,
    body: 'Help secure the Mavryk Network',
  },
  {
    title: 'Utility & Economics',
    link: pathnames.UTILITY_AND_ECONOMICS,
    body: 'All about the MVRK coin ',
  },
  {
    title: 'RWA',
    link: pathnames.RWA,
    body: 'Discover Real World Assets',
  },
];
export const exploreHeaderLinks: NavDropDownItem[] = [
  { title: 'Mavryk Domains', link: pathnames.DOMAINS, body: 'Checkout Mavryk Domains' },
  { title: 'Mavryk Wallet', link: pathnames.WALLET_TEASER, body: 'Your One-Stop Web3 Wallet' },
  {
    title: 'Protocol Treasury',
    link: pathnames.PROTOCOL_TREASURY,
    body: 'Explore On-Chain Funding',
  },
  {
    title: 'Liquidity Mining',
    link: pathnames.LIQUIDITY_MINING,
    body: 'Explore Liquidity Mining',
  },
];
export const getInvolvedHeaderLinks: NavDropDownItem[] = [
  { title: 'About us', link: pathnames.ABOUT_US, body: 'What is Mavryk?' },
  { title: 'Community', link: pathnames.COMMUNITY, body: 'Explore Mavryk community ' },
  { title: 'Pre-Launch Bounties', link: pathnames.GRANTS, body: 'Contribute to Building Mavryk' },
  {
    title: 'Foundation Delegations',
    link: pathnames.FOUNDATION_DELEGATIONS,
    body: 'All about delegations',
  },
  { title: 'Brand & Media Kit', link: pathnames.MEDIA_KIT, body: 'All about the brand' },
];
