import { useThemeContext } from 'styles/theme/theme.provider';

import styles from './themeButton.module.css';
import { Icon } from '../Icon/Icon';
import classNames from 'classnames';

export const ThemeSwitcher = () => {
  const { theme, toggleTheme } = useThemeContext();

  const isDark = theme === 'dark';
  const nextTheme = isDark ? 'light' : 'dark';

  return (
    <div
      className={styles.themeSwitcher}
      onClick={() => toggleTheme(nextTheme)}
    >
      <button
        className={classNames(styles.themeButton, {
          [styles.selectedThemeButton]: 'dark' === theme,
        })}
      >
        <Icon className={styles.themeSwitcher} id="moon" />
      </button>

      <button
        className={classNames(styles.themeButton, {
          [styles.selectedThemeButton]: 'light' === theme,
        })}
      >
        <Icon className={styles.themeSwitcher} id="sun" />
      </button>
    </div>
  );
};
