import React from 'react';
import { HeadingType } from './heading.type';
import styles from './heading.module.css';
import classNames from 'classnames';

// TODO fix fonts, old spacing was removed from figma
export const Heading = ({
  children,
  className = '',
  type = 'body',
  textColor = 'primary',
  textFormat,
  lineHeightSize = 'regular',
  ...props
}: HeadingType) => {
  return (
    <div
      className={classNames(
        styles.heading,
        styles[`line-height-${lineHeightSize}`],
        styles[`color-${textColor}`],
        {
          [styles[type]]: type !== 'none',
          [styles[textFormat ?? '']]: textFormat,
        },
        className
      )}
      {...props}
    >
      {children}
    </div>
  );
};
