import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

const WalletTeaserPage = lazy(() => import('pages/WalletTeaser/WalletTeaser.page'));

const Validator = lazy(() => import('pages/Validator/Validator'));

const UtilityAndEconomics = lazy(() => import('pages/UtilityAndEconomics/UtilityAndEconomics'));

const RWAPage = lazy(() => import('pages/RWA/RWA'));

const ProtocolTreasury = lazy(() => import('pages/ProtocolTreasury/ProtocolTreasury'));
const LiquidityMining = lazy(() => import('pages/LiquidityMining/LiquidityMining'));

const Main = lazy(() => import('pages/Main/Main'));

const Litepaper = lazy(() => import('pages/Litepaper/Litepaper'));

const Grants = lazy(() => import('pages/Grants/Grants'));

const GrantDetails = lazy(() => import('pages/Grants/GrantDetails/GrantDetails'));

const FoundationDelegations = lazy(
  () => import('pages/FoundationDelegations/FoundationDelegations')
);

const DomainsPage = lazy(() => import('pages/Domains/Domains.page'));

const Community = lazy(() => import('pages/Community/Community'));

const MediaKit = lazy(() => import('pages/MediaKit/MediaKit'));

const AboutUs = lazy(() => import('../pages/AboutUs/AboutUs'));

export const pathnames = {
  HOME: '/',
  LITEPAPER: '/litepaper',
  VALIDATOR: '/validator',
  VAILDATOR_TAB: '/validator/validatorTab',
  DELEGATOR_TAB: '/validator/delegatorTab',
  UTILITY_AND_ECONOMICS: '/utility-and-economics',
  MEDIA_KIT: '/media-kit',
  COMMUNITY: '/community',
  GRANTS: '/grants',
  GRANT_DETAILS: '/grant-details/:id',
  FOUNDATION_DELEGATIONS: '/foundation-delegations',
  PROTOCOL_TREASURY: '/protocol-treasury',
  LIQUIDITY_MINING: '/liquidity-mining',
  RWA: '/rwa',
  ABOUT_US: '/about-us',
  DOMAINS: '/domains',
  WALLET_TEASER: '/wallet',
} as const;

export const routes = [
  {
    path: pathnames.HOME,
    exact: true,
    element: Main,
  },

  {
    path: pathnames.LITEPAPER,
    element: Litepaper,
  },
  {
    path: pathnames.VALIDATOR,
    element: Validator,
    children: [
      {
        path: 'validatorTab',
        element: <Validator />,
      },
      {
        path: 'delegatorTab',
        element: <Validator />,
      },

      {
        index: true,
        element: <Navigate to="/validator" replace />,
      },
    ],
  },
  {
    path: `${pathnames.VALIDATOR}/:tabId`,
    element: Validator,
  },
  {
    path: pathnames.UTILITY_AND_ECONOMICS,
    element: UtilityAndEconomics,
  },
  {
    path: pathnames.MEDIA_KIT,
    element: MediaKit,
  },
  {
    path: pathnames.COMMUNITY,
    element: Community,
  },
  { path: pathnames.FOUNDATION_DELEGATIONS, element: FoundationDelegations },
  { path: pathnames.RWA, element: RWAPage },
  { path: pathnames.PROTOCOL_TREASURY, element: ProtocolTreasury },
  { path: pathnames.LIQUIDITY_MINING, element: LiquidityMining },
  { path: pathnames.GRANTS, element: Grants },
  { path: pathnames.GRANT_DETAILS, element: GrantDetails },
  { path: pathnames.DOMAINS, element: DomainsPage },
  { path: pathnames.ABOUT_US, element: AboutUs },
  { path: pathnames.WALLET_TEASER, element: WalletTeaserPage },
  { path: '*', element: Navigate.bind(null, { to: '/', replace: true }) },
];
