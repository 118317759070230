import React from 'react';

import classNames from 'classnames';
import styles from './button.module.css';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  btnType?: 'filled' | 'outlined';
  // blue and black for outlined type | blue and orange for filled type
  btnColor?: 'blue' | 'orange' | 'black';
  size?: 'small' | '';
}

export const Button = ({
  children,
  className = '',
  btnType = 'filled',
  btnColor = 'blue',
  size = '',
  ...props
}: ButtonProps) => {
  return (
    <button
      className={classNames(
        styles.buttonElement,
        styles[btnType],
        styles[btnColor],
        {
          [styles[size]]: Boolean(size),
        },
        className
      )}
      {...props}
    >
      {children}
    </button>
  );
};
