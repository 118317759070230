import React, {
  useContext,
  useLayoutEffect,
  useRef,
  useState,
  createContext,
  useMemo,
} from 'react';
import { useLocation } from 'react-router-dom';
import { useClickAway } from 'react-use';

import classNames from 'classnames';
import sharedStyles from 'styles/sharedStyles.module.css';

import styles from './navigationDropDown.module.css';
import { NavigationDropDownContext } from './navigationDropDown.types';

const navigationDropDownContext = createContext<NavigationDropDownContext>(undefined!);

export const useNavigationDropDownContext = () => {
  const context = useContext(navigationDropDownContext);

  if (!context) {
    throw new Error('useNavigationDropDownContext should be used within NavigationDropDown');
  }

  return context;
};

export const NavigationDropDown = ({ children }: { children: React.ReactNode }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [hasTransition, setHasTransition] = useState(true);
  const ref = useRef<HTMLDivElement | null>(null);

  const { pathname } = useLocation();
  useClickAway(ref, () => {
    setIsOpen(false);
    setHasTransition(true);
  });

  // when changing the route - do NOT play the hide transition
  useLayoutEffect(() => {
    setHasTransition(true);
  }, [pathname]);

  const toggleVisibility = () => {
    setIsOpen(!isOpen);
  };

  const contextValue = useMemo(
    () => ({
      isOpen,
      setHasTransition,
    }),
    [isOpen]
  );

  return (
    <navigationDropDownContext.Provider value={contextValue}>
      <div
        ref={ref}
        className={classNames(styles.navDropDown, {
          [sharedStyles['transiiton-off']]: !hasTransition,
        })}
        onClick={toggleVisibility}
      >
        {children}
      </div>
    </navigationDropDownContext.Provider>
  );
};
