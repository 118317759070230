import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { sleep } from 'utils/sleep';

export const useScrollToElement = () => {
  const { pathname, hash } = useLocation();

  useEffect(() => {
    async function execute() {
      const _hash = hash.split('#').pop();

      if (_hash) {
        const el = document.getElementById(_hash);

        // user delay to wait for elements to render
        await sleep(200);
        el?.scrollIntoView({
          block: 'start',
        });
      } else {
        window.scrollTo(0, 0);
      }
    }

    execute();
  }, [pathname, hash]);
};
