import React, {
  useState,
  useEffect,
  useLayoutEffect,
  useCallback,
  createContext,
  useContext,
  useMemo,
} from 'react';
import { Helmet } from 'react-helmet-async';
import { getItem, setItem } from '../../utils/localStorage.helper';
// const
import { LIGHT_THEME, DARK_THEME } from './theme.const';

// types
import type { ThemeColorType, ThemeContext, Props } from './theme.type';

export const themeContext = createContext<ThemeContext>(undefined!);

// TODO remove this var and logic with it after adding the light theme

const IS_ONLY_DARK_THEME = true;

export const ThemeProvider = (props: Props) => {
  const [theme, setTheme] = useState<ThemeColorType>(() =>
    IS_ONLY_DARK_THEME ? DARK_THEME : LIGHT_THEME
  );

  const setDataAttrTheme = () => {
    if (document.documentElement && document.documentElement.dataset) {
      document.documentElement.dataset.theme = theme;
    }
  };

  useEffect(() => {
    const isOsDarkMode = window.matchMedia('(prefers-color-scheme: dark)');
    const themeLocal = getItem<ThemeColorType>('theme');
    const osMode = isOsDarkMode.matches ? DARK_THEME : LIGHT_THEME;

    if (IS_ONLY_DARK_THEME) {
      setTheme(DARK_THEME);
    } else {
      setTheme(themeLocal || osMode);
    }
  }, []);

  useLayoutEffect(() => {
    setDataAttrTheme();
  }, [theme]);

  const toggleTheme = useCallback((value: ThemeColorType = LIGHT_THEME): void => {
    const _value = IS_ONLY_DARK_THEME ? DARK_THEME : value;

    setTheme(_value);
    setItem('theme', _value);
  }, []);

  const value = useMemo(
    () => ({
      theme,
      toggleTheme,
    }),
    [theme, toggleTheme]
  );

  return (
    <themeContext.Provider value={value}>
      {theme ? (
        <Helmet>
          <meta name="theme-color" content={theme} />
        </Helmet>
      ) : null}
      {props.children}
    </themeContext.Provider>
  );
};

export const useThemeContext = () => {
  const context = useContext(themeContext);
  if (!context) {
    throw new Error('useThemeContext hook should be used within ThemeProvider');
  }
  return context;
};

export default ThemeProvider;
