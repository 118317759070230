import { createContext, useContext, useRef, MutableRefObject, ReactNode } from 'react';
import { useNavigate, NavigateFunction } from 'react-router-dom';

const StableNavigateContext = createContext<MutableRefObject<NavigateFunction> | null>(null);

type StableNavigateContextProviderProps = {
  children: ReactNode;
};

const StableNavigateContextProvider = ({ children }: StableNavigateContextProviderProps) => {
  const navigate = useNavigate();
  const navigateRef = useRef<NavigateFunction>(navigate);

  return (
    <StableNavigateContext.Provider value={navigateRef}>{children}</StableNavigateContext.Provider>
  );
};

const useStableNavigate = (): NavigateFunction => {
  const navigateRef = useContext(StableNavigateContext);
  if (navigateRef?.current === null) throw new Error('StableNavigate context is not initialized');

  // @ts-expect-error
  return navigateRef?.current;
};

export { StableNavigateContext, StableNavigateContextProvider, useStableNavigate };
