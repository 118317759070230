import { Suspense, useEffect, useMemo, useRef } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import lottie from 'lottie-web';
import LoaderLottie from 'lottie/loader.json';

import { LottieParameters } from 'hooks/lottie';

import styles from './suspense.module.css';

export const SuspenseFallBack = () => {
  const ref = useRef(null);

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    if (ref.current) {
      timeout = setTimeout(() => {
        lottie.loadAnimation({
          container: ref.current!,
          animationData: LoaderLottie,
          loop: true,
          autoplay: true,
          renderer: 'canvas',
          name: 'loader',
          rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
          },
        });
      }, 100);
    }

    return () => {
      lottie.destroy('loader');
      if (timeout) clearTimeout(timeout);
    };
  }, []);

  return (
    <div className={styles.susPenseWrapper}>
      <div ref={ref} />
    </div>
  );
};

export const SuspenseLayout = () => {
  const location = useLocation();
  return (
    <Suspense fallback={<SuspenseFallBack />} key={location.key}>
      <Outlet />
    </Suspense>
  );
};
