import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';

// components
import { Icon } from 'components/Icon/Icon';
// hooks
import { useWindowDimensions } from 'hooks/useWindowDimensions';

// styles
import sharedStyles from 'styles/sharedStyles.module.css';
import styles from './footer.module.css';

// consts
import { footerNavigationData, socialMediaData, FooterNavItem } from './footer.const';

type FooterNavBlockProps = {
  title: string;
  items: FooterNavItem[];
};

const SocialMedia = () => {
  const { width } = useWindowDimensions();

  return (
    <div
      className={classNames(styles.socialMedia, {
        [styles.gridStyle]: width < 480 && socialMediaData.length > 3,
      })}
    >
      {socialMediaData.map((item) => (
        <a key={item.name} href={item.link} rel="noreferrer" target="_blank">
          <Icon id={item.name} />
        </a>
      ))}
    </div>
  );
};

const FooterNavBlock = ({ title, items }: FooterNavBlockProps) => {
  const [isOpen, setIsOpen] = useState(false);

  // opened section by default
  useEffect(() => {
    if (title.toLowerCase() === 'discover') {
      setIsOpen(true);
    }
  }, []);

  return (
    <div className={styles.footerNavBlock}>
      <div
        className={classNames(styles.title, {
          [styles.titleExpanded]: isOpen,
        })}
        onClick={() => setIsOpen(!isOpen)}
      >
        {title}
        <Icon id="arrow-down" />
      </div>
      <div
        className={classNames(styles.footerNavBlock, {
          [styles.footerMenuDisabledSection]: !isOpen,
        })}
      >
        {items.map((item) => (
          <a
            key={item.title}
            href={item.httpLink}
            rel="noreferrer"
            target="_blank"
            className={styles.link}
          >
            {item.title}
          </a>
        ))}
      </div>
    </div>
  );
};

export const Footer = () => {
  const navigate = useNavigate();

  return (
    <footer className={styles.footerBg}>
      <div className={sharedStyles.container}>
        <div className={styles.footer}>
          <div className={styles.logo}>
            <button className={styles.logoInner} onClick={() => navigate('/')}>
              <Icon id="logo" className={styles.logoIcon} />
              <Icon id="mavryk-text" className={styles.logoMavryk} />
            </button>

            <SocialMedia />
          </div>
          <div className={styles.footerInner}>
            {Object.entries(footerNavigationData).map(([title, values]) => (
              <FooterNavBlock key={title} title={title} items={values} />
            ))}
          </div>
        </div>
      </div>
    </footer>
  );
};
