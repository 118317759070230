import { Routes, Route, useLocation } from 'react-router-dom';

import { useHashNavigation } from 'hooks/useHashNavigation';
import { useScrollToElement } from 'hooks/useScrollToElement';

import { SuspenseLayout } from './SuspenseLayout';
import { routes } from './routes';

export const AppRoutes = () => {
  // used mostly for FAQ navigation or scroll to top if no faq id
  useScrollToElement();
  // navigate to element by id
  const { hash } = useLocation();
  useHashNavigation(hash);

  return (
    <Routes>
      <Route element={<SuspenseLayout />}>
        {routes.map((route, i) => (
          <Route key={String(i + route.path)} path={route.path} element={<route.element />} />
        ))}
      </Route>
    </Routes>
  );
};
