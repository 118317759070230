import React from 'react';
import { Link } from 'react-router-dom';

import classNames from 'classnames';
import { Icon } from 'components/Icon/Icon';

import { useNavigationDropDownContext } from '../NavigationDropDown';
import styles from '../navigationDropDown.module.css';
import { NavDropDownItem } from '../navigationDropDown.types';

export const DropDownNavigationHeader = ({ children }: { children: React.ReactNode }) => {
  const { isOpen } = useNavigationDropDownContext();

  return children ? (
    <div
      className={classNames(styles.mainText, {
        [styles.active]: isOpen,
      })}
    >
      {children}
      <Icon id="arrow-down" />
    </div>
  ) : null;
};

export const DropDownNavigationList = ({
  children,
  align = 'right',
}: {
  children: React.ReactNode;
  align?: 'left' | 'right';
}) => {
  const { isOpen } = useNavigationDropDownContext();
  return children ? (
    <ol
      className={classNames(styles.navDropDownList, {
        [styles.active]: isOpen,
        [styles[align]]: align,
      })}
    >
      {children}
    </ol>
  ) : null;
};

export const DropDownNavigationItem = ({ item }: { item: NavDropDownItem }) => {
  const { setHasTransition } = useNavigationDropDownContext();

  return item ? (
    <Link
      key={item.title}
      to={item.link}
      onClick={() => setHasTransition(false)}
      className={styles.navDropDownItem}
    >
      <div className={styles.navItemHeader}>{item.title}</div>
      {item.body && <div className={styles.navItemBody}>{item.body}</div>}
    </Link>
  ) : null;
};
