import { useEffect, useState } from 'react';

import styles from './toaster.module.css';
import { sleep } from 'utils/sleep';
import { useToasterContext } from '../toaster.provider';
import { ToasterAnimationType, ToasterMessage } from '../toaster.provider.type';

import { Icon } from 'components/Icon/Icon';
import {
  ANIMATION_DURATION,
  TOASTER_HIDE,
  TOASTER_LOADING,
  TOASTER_REVEAL,
  TOASTS_LIMIT,
  TOAST_ICON_MAPPER,
  TOAST_TIME_TO_LIVE,
  getColorByToasterStatus,
} from '../toaster.provider.const';

const Toast = ({ toast }: { toast: ToasterMessage }) => {
  const [toastAnimation, setToastAnimation] =
    useState<ToasterAnimationType>(TOASTER_REVEAL);

  const { hideToasterMessage, deleteToasterFromArray } = useToasterContext();
  const { title, message, type, unique, hide } = toast;

  // effect to update toast property "hide" to 'true' for playing hide animation
  useEffect(() => {
    if (type !== TOASTER_LOADING) {
      (async () => {
        await sleep(TOAST_TIME_TO_LIVE);
        hideToasterMessage(unique);
      })();
    }
  }, [hideToasterMessage, type, unique]);

  // play hide animation and completely delete toast
  useEffect(() => {
    if (hide) {
      (async () => {
        setToastAnimation(TOASTER_HIDE);
        // wait for animation finish
        await sleep(ANIMATION_DURATION);
        deleteToasterFromArray(unique);
      })();
    }
  }, [deleteToasterFromArray, hide, unique]);

  return (
    <div
      style={{
        // @ts-ignore
        '--animation-type': toastAnimation,
        '--delay': ANIMATION_DURATION,
        '--distance': 500,
        '--color-toaster': getColorByToasterStatus(type),
      }}
      className={styles.toaster}
    >
      <div className={styles.toasterIcon}>
        <Icon id={TOAST_ICON_MAPPER[type]} />
      </div>

      <div className={styles.toasterContent}>
        <div className={styles.title}>{title}</div>
        <div className={styles.message}>{message}</div>
      </div>

      <div className={styles.toasterCountdown} />
    </div>
  );
};

export const ToasterMessages = () => {
  const { messages, deleteToasterFromArray } = useToasterContext();

  // remove toasts starting from the oldest if messages limit was passed
  useEffect(() => {
    if (messages.length > TOASTS_LIMIT) {
      const messagesToRemoveCount = messages.length - TOASTS_LIMIT;
      const _messages = messages.filter((m) => m.type !== TOASTER_LOADING);
      Array.from({ length: messagesToRemoveCount }).forEach((_, idx) => {
        if (!_messages[idx]) return;
        deleteToasterFromArray(_messages[idx].unique);
      });
    }
  }, [messages, deleteToasterFromArray]);

  if (!messages.length) return null;

  return (
    <div className={styles.toasterContainer}>
      {messages.map((m) => (
        <Toast key={m.unique} toast={m} />
      ))}
    </div>
  );
};
