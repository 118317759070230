import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, matchPath } from 'react-router-dom';
import { Link } from 'react-router-dom';

import classNames from 'classnames';
// components
import { Button } from 'components/Button/Button';
import { Heading } from 'components/Heading/Heading';
import { Icon } from 'components/Icon/Icon';
import { MobileMenu } from 'components/Menu/MobileMenu';
import { NavigationDropDown } from 'components/NavigationDropDown/NavigationDropDown';
import {
  DropDownNavigationHeader,
  DropDownNavigationItem,
  DropDownNavigationList,
} from 'components/NavigationDropDown/components/NavigationItems';
import { ThemeSwitcher } from 'components/ThemeButton/ThemeButton';
import sharedStyles from 'styles/sharedStyles.module.css';

// helpers
import {
  blueRecolorPages,
  exploreHeaderLinks,
  getInvolvedHeaderLinks,
  learnHeaderLinks,
  litepaperNav, // pagesWithoutStayTunedButton,
  pagesWithoutThemeButton,
} from './header.const';
// styles
import styles from './header.module.css';
import { getRedirectLinkById } from './utils/redirect.util';

export const Header = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);

  const isBlueRecolor = useMemo(
    () => blueRecolorPages.some((item) => matchPath(item, pathname)),
    [pathname]
  );

  const isActiveThemeButton = useMemo(
    () => !pagesWithoutThemeButton.some((currentPathname) => matchPath(currentPathname, pathname)),
    [pathname]
  );

  // const isActiveStayTuned = useMemo(
  //   () =>
  //     !pagesWithoutStayTunedButton.some((currentPathname) => matchPath(currentPathname, pathname)),
  //   [pathname]
  // );

  useEffect(() => {
    setIsOpen(false);
  }, [pathname]);

  return (
    <div
      className={classNames({
        [styles.blueRecolor]: isBlueRecolor,
      })}
    >
      <div className={sharedStyles.container}>
        <header className={styles.header}>
          <button className={styles.logo} onClick={() => navigate('/')}>
            <Icon id="logo" className={styles.logoIcon} />
            <Icon id="mavryk-text" className={styles.logoMavryk} />
          </button>

          <div className={styles.navigation}>
            <NavigationDropDown>
              <DropDownNavigationHeader>Learn</DropDownNavigationHeader>
              <DropDownNavigationList>
                {learnHeaderLinks.map((item) => (
                  <DropDownNavigationItem key={item.title} item={item} />
                ))}
              </DropDownNavigationList>
            </NavigationDropDown>
            <NavigationDropDown>
              <DropDownNavigationHeader>Explore</DropDownNavigationHeader>
              <DropDownNavigationList>
                {exploreHeaderLinks.map((item) => (
                  <DropDownNavigationItem key={item.title} item={item} />
                ))}
              </DropDownNavigationList>
            </NavigationDropDown>
            <NavigationDropDown>
              <DropDownNavigationHeader>Get Involved</DropDownNavigationHeader>
              <DropDownNavigationList>
                {getInvolvedHeaderLinks.map((item) => (
                  <DropDownNavigationItem key={item.title} item={item} />
                ))}
              </DropDownNavigationList>
            </NavigationDropDown>

            <Link key={litepaperNav.title} to={litepaperNav.link}>
              <Heading className={styles.link} type="none">
                {litepaperNav.title}
              </Heading>
            </Link>

            <Button
              btnType="outlined"
              btnColor="black"
              type="button"
              className={classNames(styles.stayTuned, styles.isActiveStayTuned)}
              onClick={() => navigate(getRedirectLinkById('stay-tuned'))}
            >
              Stay Tuned
            </Button>

            {isActiveThemeButton && (
              <div>
                <ThemeSwitcher />
              </div>
            )}

            <button
              onClick={() => setIsOpen(!isOpen)}
              className={classNames(styles.burgerMenu, {
                [styles.menuIsOpen]: isOpen,
              })}
            >
              <Icon id="menu" />
            </button>
          </div>
        </header>

        <MobileMenu
          open={isOpen}
          closeMobileMenu={() => setIsOpen(false)}
          isActiveThemeButton={isActiveThemeButton}
        />
      </div>
    </div>
  );
};
