import React, { useState, useCallback, createContext, useContext, useMemo } from 'react';

import { GrantType } from './grants.type';
// mocks
import mockedGrants from './mocks/grants.mock.json';

export const APPLY_GRANT_TAB = 'apply';
export const GENERAL_INFO_GRANT_TAB = 'generalInfo';

type GrantTab = typeof APPLY_GRANT_TAB | typeof GENERAL_INFO_GRANT_TAB;

type GrantContextType = {
  activeGrantTab: GrantTab;
  toggleTab: (arg: GrantTab) => void;
  grants: GrantType[];
};

type Props = {
  children: React.ReactNode;
};

export const grantContext = createContext<GrantContextType>(undefined!);

export const GrantProvider = ({ children }: Props) => {
  const [activeGrantTab, setActiveGrantTab] = useState<GrantTab>(GENERAL_INFO_GRANT_TAB);

  const toggleTab = useCallback((arg: GrantTab) => {
    setActiveGrantTab(arg);
  }, []);

  const value = useMemo(
    () => ({
      activeGrantTab,
      toggleTab,
      grants: mockedGrants,
    }),
    [activeGrantTab, toggleTab]
  );

  return <grantContext.Provider value={value}>{children}</grantContext.Provider>;
};

export const useGrantContext = () => {
  const context = useContext(grantContext);
  if (!context) {
    throw new Error('useGrantContext hook should be used within GrantProvider');
  }
  return context;
};

export default GrantProvider;
