import { useCallback, useEffect, useRef, useState } from 'react';

import classNames from 'classnames';
import { Icon } from 'components/Icon/Icon';
import { NavDropDownItem } from 'components/NavigationDropDown/navigationDropDown.types';
import { sleep } from 'utils/sleep';

import styles from '../mobileMenu.module.css';
import { BurgerSubHeadingItem } from './BurgerSubHeadingItem';

type BurgerExpanderItemProps = {
  text: string;
  items: NavDropDownItem[];
  toggleMenuItem: (idx: number) => void;
  serialNumber: number;
  isOpen: boolean;
};

export const BurgerExpanderItem = ({
  text,
  items,
  toggleMenuItem,
  serialNumber,
  isOpen = false,
}: BurgerExpanderItemProps) => {
  const ref = useRef<HTMLDivElement | null>(null);

  const scrollIntoView = () => {
    ref.current?.scrollIntoView({
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    async function execute() {
      if (isOpen) {
        await sleep(300);
        scrollIntoView();
      }
    }

    execute();
  }, [isOpen]);

  const toggleOpenFlag = () => {
    toggleMenuItem(serialNumber);
  };

  const handleItemClick = async () => {
    toggleOpenFlag();
  };

  return (
    <>
      <div
        ref={ref}
        className={classNames(styles.link, styles.expanderHeader, {
          [styles.active]: isOpen,
        })}
        onClick={handleItemClick}
      >
        {text}
        <Icon id="arrow-down" />
      </div>
      <section
        className={classNames(styles.expanderItemList, {
          [styles.active]: isOpen,
        })}
      >
        {items.map((item) => (
          <BurgerSubHeadingItem key={item.title} item={item} />
        ))}
      </section>
    </>
  );
};
