import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter as Router } from 'react-router-dom';

import { Footer } from 'components/Footer/Footer';
import { Header } from 'components/Header/Header';
import { ToasterMessages } from 'components/Toaster/components/ToasterMessages';
import ToasterProvider from 'components/Toaster/toaster.provider';
// providers
import { GrantProvider } from 'pages/Grants/grants.provider';
import { StableNavigateContextProvider } from 'providers/StableNavigation';
import { AppRoutes } from 'routes/AppRoutes';
import 'styles/theme/grid.module.css';
import ThemeProvider from 'styles/theme/theme.provider';

// Add providers here, not at index.tsx
const App = () => {
  return (
    <Router>
      <StableNavigateContextProvider>
        <HelmetProvider>
          <ToasterProvider>
            <ThemeProvider>
              <GrantProvider>
                <Header />
                <AppRoutes />
                <ToasterMessages />
                <Footer />
              </GrantProvider>
            </ThemeProvider>
          </ToasterProvider>
        </HelmetProvider>
      </StableNavigateContextProvider>
    </Router>
  );
};

export default App;
