import { NavDropDownItem } from 'components/NavigationDropDown/navigationDropDown.types';
import { Link } from 'react-router-dom';

import styles from '../mobileMenu.module.css';

export const BurgerSubHeadingItem = ({ item }: { item: NavDropDownItem }) => {
  return item ? (
    <Link key={item.title} to={item.link} className={styles.subHeadingItem}>
      <div className={styles.subHeadingItemHeader}>{item.title}</div>
      {item.body && <div className={styles.subHeadingItemBody}>{item.body}</div>}
    </Link>
  ) : null;
};
